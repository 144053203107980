import React, { Component } from 'react';
import axios from '../axios';
import moment from 'moment';
import Overlay from './Overlay';
import Modal from './Modal';
import { t } from 'typy';
import { loadStripe } from '@stripe/stripe-js';

class Session extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            editCode: '',
            message: '',
            expandSignup: false,
            cancelFor: null,    // data with details for signup cancel
            inFlight: false,
            inPayment: false,
            payForId: null,
            paymentInfo: null,
        };
    }

    signupClicked = () => {
        this.setState({expandSignup: true, email: '', message: ''});
    }

    cancel = () => {
        this.close();
    }

    close = () => {
        this.setState({email: '', expandSignup: false, message: '', cancelFor: null, editCode: ''});
    }

    onEmailChange = value => {
        this.setState({email: value.trim()});
    }

    onEditCodeChange = value => {
        this.setState({editCode: value.trim()});
    }

    openCancel = signupId => {
        this.setState({cancelFor: signupId});
    }

    initPayment = signupId => {
        this.setState({inPayment: true, inFlight: true, message: null, payForId: signupId, paymentInfo: null});
        // fetch payment details and prepare stripe session
        axios.post('/payment', {signupId})
            .then(response => {
                if (response.data.success) {
                    this.setState({paymentInfo: response.data.paymentInfo})
                }
                if (response.data.message) {
                    this.setState({message: response.data.message});
                }
            })
            .catch(error => {console.log('error', error)})
            .then(() => {
                this.setState({inFlight: false});
            });
    }

    submitPayment = async () => {
        const stripePublicKey  = t(this.state, 'paymentInfo.stripe_public_key').safeString;
        const stripeCheckoutId = t(this.state, 'paymentInfo.stripe_checkout_session_id').safeString;
        if (!stripePublicKey || !stripeCheckoutId) {
            this.setState({message: 'Cannot find payment data'});
            return;
        }

        const stripe = await loadStripe(stripePublicKey);
        stripe.redirectToCheckout({
            // Make the id field from the Checkout Session creation API response
            // available to this file, so you can provide it as parameter here
            // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
            sessionId: stripeCheckoutId
        }).then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        });
    }

    submit = () => {
        this.setState({inFlight: true, message: ''});
        const data = {
            group: this.props.group,
            session: this.props.id,
            email: this.state.email
        }
        axios.post('/signup', data)
            .then(response => {
                if (response.data.success) {
                    this.close();
                    this.props.onNewSignup();
                }
                if (response.data.message) {
                    this.setState({message: response.data.message});
                }
            })
            .catch(error => {console.log('error', error)})
            .then(() => {
                this.setState({inFlight: false});
            });
    }

    submitCancel = () => {
        this.setState({inFlight: true, message: ''});
        axios.post('/delete', {id: this.state.cancelFor, editCode: this.state.editCode})
            .then(response => {
                if (response.data.success) {
                    this.close();
                    this.props.onNewSignup();
                }
                if (response.data.message) {
                    this.setState({message: response.data.message});
                }
            })
            .catch(error => {console.log('error', error)})
            .then(() => {
                this.setState({inFlight: false});
            });
    }

    getAvailableSlotsCount = () => {
        return Math.max(0, this.props.maxPlayers + this.props.maxSpares - (this.props.signups || []).length);
    }

    getProductName = () => {
        return 'Lessons - ' + this.props.groupLabel;
    }

    getGetPayeeContact = () => {
        return this.props.signups.filter(signup => signup.id === this.state.payForId)[0].name;
    }

    getOpenTime = () => {
        return this.props.openTimestamp ? moment.unix(this.props.openTimestamp).format('lll') : 'unknown'
    }

    renderActions() {
        if (this.getAvailableSlotsCount() === 0) {
            return (
                <h5>
                    <div className="badge badge-danger">Session is fully booked.</div>
                </h5>
            )
        }

        if (this.state.expandSignup) {
            const submitDisabled = this.state.email.length === 0;
            return (
                <div className="">
                    {this.state.message && <div className="alert alert-danger">{this.state.message}</div>}
                    <input className="form-control" placeholder="Your STC email" value={this.state.email} onChange={e => this.onEmailChange(e.target.value)} />
                    <div className="mt-2 text-right">
                        { this.state.inFlight && <span className="font-weight-bold pr-1">Please wait...</span> }
                        <button className="btn btn-secondary mr-2" onClick={this.cancel} disabled={this.state.inFlight}>Cancel</button>
                        <button className="btn btn-primary" onClick={this.submit} disabled={submitDisabled || this.state.inFlight}>Submit</button>
                    </div>
                </div>
            )
        }

        return (
            <div className="text-right">
                <button className="btn btn-success" onClick={this.signupClicked}>Sign Up</button>
            </div>
        )
    }

    renderSignups() {
        const rows = [], rowsSpares = [];
        const cancelSignupId = this.state.cancelFor;
        const pushData = (arr, index, name, id, paymentStatus, allowPay) => {
            arr.push(
                <tr key={index}>
                    <td width="5%">{index}.</td>
                    <td>{ name && <strong>{name}</strong> }</td>
                    <td width="30%">
                        { name && paymentStatus === 'paid' &&
                            <span className="text-muted">Fees paid</span>
                        }
                        { name && !cancelSignupId && paymentStatus !== 'paid' &&
                            <div className="d-flex">
                                <button className="btn btn-sm text-danger" onClick={(e) => {this.openCancel(id)}}>Cancel</button>
                                {allowPay && <button className="btn btn-sm btn-primary text-nowrap" onClick={(e) => {this.initPayment(id)}}>Pay</button>}
                            </div>
                        }
                    </td>
                </tr>
            );

            if (id === cancelSignupId) {
                const submitDisabled = this.state.editCode.length === 0 || this.state.inFlight;
                arr.push(
                    <tr key={index + '-cancel'}>
                        <td width="5%"></td>
                        <td colSpan="2">
                            {this.state.message && <div className="alert alert-danger">{this.state.message}</div>}
                            <input className="form-control" placeholder="Cancellation Code" value={this.state.editCode} onChange={e => this.onEditCodeChange(e.target.value)} />
                            <small className="form-text text-muted">Code has been emailed to you in confirmation email.</small>
                            <div className="mt-2 text-right">
                                { this.state.inFlight && <span className="font-weight-bold pr-1">Please wait...</span> }
                                <button className="btn btn-secondary mr-2" onClick={this.close} disabled={this.state.inFlight}>Close</button>
                                <button className="btn btn-danger" onClick={this.submitCancel} disabled={submitDisabled}>Cancel Signup</button>
                            </div>
                        </td>
                    </tr>
                );
            }
        }

        var data;
        for (var i = 0; i < this.props.maxPlayers; i++) {
            data = this.props.signups && this.props.signups[i] ? this.props.signups[i] : {}
            pushData(rows, i + 1, data.name, data.id, data.payment_status, true)
        }
        for (var j = i; j < i + this.props.maxSpares; j++ ) {
            data = this.props.signups && this.props.signups[j] ? this.props.signups[j] : {}
            pushData(rowsSpares, j - i + 1, data.name, data.id, data.payment_status, false)
        }
        return (
            <div className="mt-1">
                <table className="table table-striped table-bordered m-0">
                    <tbody>
                        { rows }
                    </tbody>
                </table>
                <div className="my-1 mt-3 font-weight-bold">Waiting List:</div>
                <table className="table table-striped table-bordered m-0">
                    <tbody>
                        { rowsSpares }
                    </tbody>
                </table>
            </div>
        )
    }

    renderPayment () {
        const product = t(this.state.paymentInfo, 'product').safeString;
        const amount  = t(this.state.paymentInfo, 'amount').safeString;
        const contact = this.getGetPayeeContact();

        if (this.state.message) {
            return (
                <div>
                    <Overlay clicked={() => this.setState({inPayment: false})} />
                    <Modal>
                        <h4>Lessons Payment Overview</h4>
                        <div className="alert alert-danger my-2">{this.state.message}</div>
                        <div className="mt-3">
                            <div className="text-right">
                                <button className="btn btn-secondary" onClick={() => this.setState({inPayment: false})}>Close</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
        }

        return (
            <div>
                <Overlay clicked={() => this.setState({inPayment: false})} />
                <Modal>
                    <h4>Lessons Payment Overview</h4>
                    <div className="card bg-light shadow">
                        <div className="p-3">
                            <div className="">Product: <strong>{product}</strong></div>
                            <div className="">Fee: <strong>{amount}</strong></div>
                            <div className="">Contact: <strong>{contact}</strong></div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <div className="alert alert-info">
                            Please review the details above and click on Proceed to Payment below.{' '}
                            <strong>You will be redirected to Stripe payment portal where you can make the payment.</strong>{' '}
                            Saanich Tennis Club doesn't collect or store payment credentials like credit card numbers.
                        </div>
                        <div className="text-right">
                            <button className="btn btn-secondary" onClick={() => this.setState({inPayment: false})}>Cancel</button>
                            <button className="btn btn-primary ml-2" onClick={this.submitPayment} disabled={this.state.inFlight}>Proceed to Payment</button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    render () {
        return (
            <div className="session">
                { this.state.inPayment && this.renderPayment() }
                <div>
                    <strong>Dates:</strong>{' '}
                    <span>{this.props.dates}</span>
                </div>
                <div>
                    <strong>Fee:</strong>{' '}
                    <span>CAD ${this.props.fee}</span>
                </div>
                {!this.props.isRegistrationOpen ? (
                    <div className="alert alert-danger">
                        Registration for {this.props.label} will open at: {this.getOpenTime()}
                        <a href="/" className="d-inline-block ml-3">Reload</a>
                    </div>
                ) : (this.renderSignups()) }
                <div className="mt-3">
                    { this.props.isRegistrationOpen && this.renderActions() }
                </div>
                <hr className="my-3" />
            </div>
        )
    }
}

export default Session;