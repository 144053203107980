import React from 'react';

const Layout = (props) => {
    return (
        <div>
            <nav className="navbar navbar-light bg-primary d-print mb-4">
                <a className="navbar-brand text-white py-3" href="/">Saanich Tennis Club - Lessons {props.year}</a>
            </nav>
            <div className="container-fluid">{props.children}</div>
        </div>
    )
}

export default Layout;