import React from 'react';
import './Group.css';
import Session from './Session';

const Group = (props) => {

    const submitClicked = (data) => {
        props.submitClicked({...data, group: props.group});
    }

    const sessions = props.sessions.map(sess =>
        <Session
            submitSignup={submitClicked}
            { ...sess }
            onNewSignup={props.onNewSignup}
            group={props.group}
            groupLabel={props.label}
            key={sess.id}
        />
    );

    return (
        <div className="Group m-2 p-3 border border-primary shadow">
            <div className="d-flex justify-content-between mt-1">
                <h5 className="mb-0">{props.label}</h5>
                <h5 className=""><div className="badge badge-warning">LEVEL: {props.level}</div></h5>
            </div>
            <div>
                <strong>Time:</strong>{' '}
                <span>{props.time}</span>
            </div>
            <hr className="my-3" />
            { sessions }
        </div>
    )
}

export default Group;