import React, { Component } from 'react';
import Layout from './components/Layout';
import Lessons from './components/Lessons';
import './App.css';

class App extends Component
{
  constructor(props) {
    super(props);
    this.state = {
        year: null
    };
  }

  componentDidMount () {
    // enforce HTTPS in production (required for Stripe)
    if (window.location.hostname !== 'localhost' && window.location.protocol !== 'https:') {
      window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
  }

  render () {
    return (
      <Layout year={this.state.year}>
        <div className="bg-transparent text-dark p-2">
          <div className="">
              To sign up for a lesson, please click on <span className="badge badge-success">Sign Up</span> in an appropriate group.{' '}
              There are <strong>8 guaranteed</strong> spots plus additional waiting-list spots for each group.{' '}
              Players register on a <strong>first come - first serve</strong> basis. Players from the waiting list will automatically move up as players higher in the list cancel.{' '}
              You will get notified by email when you sign up or are moved from the waiting list with further details on how to confirm your spot by paying, and how to cancel.{' '}
          </div>
        </div>
        <hr  className="mt-1" />
        <Lessons onYearUpdate={y => this.setState({year: y})} />
      </Layout>
    );
  }
}

export default App;
