import React, { Component } from 'react';
import axios from '../axios';
import moment from 'moment';
import Group from './Group';
import Overlay from './Overlay';
import Modal from './Modal';
import qs from 'qs';

class Lessons extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            isOpen: null,
            openTimestamp: null,
            lessons: [],
            inFlight: false,
            isLoading: false,
            paymentInfo: null,
        };
    }

    componentDidMount() {
        this.fetchLessons();

        // check if payment cancelled
        const locationSearch = window.location.search
        const params = qs.parse(locationSearch, { ignoreQueryPrefix: true });
        if (params['payment-cancel'] !== undefined || params['payment-error'] !== undefined) {
            this.setState({paymentInfo: 'cancelled'});
        }
        if (params['payment-success'] !== undefined) {
            this.setState({paymentInfo: 'success'});
        }
    }

    onNewSignup = () => {
        this.fetchLessons();
    }

    getOpenTime = () => {
        return this.state.openTimestamp ? moment.unix(this.state.openTimestamp).format('lll') : 'unknown'
    }

    fetchLessons = () => {
        this.setState({isLoading: true});
        return axios.get('/lessons')
            .then(response => {
                this.setState({lessons: response.data.lessons, isOpen: response.data.isRegistrationOpen, openTimestamp: response.data.openTimestamp});
                this.props.onYearUpdate(response.data.year);
            })
            .catch(error => {
                console.log('error', error);
            })
            .then(() => {
                this.setState({isLoading: false});
            });
    }

    closePaymentInfo = () => {
        window.location.href = '/';
    }

    renderPaymentInfo = () => {
        const isCancelled = this.state.paymentInfo === 'cancelled';
        const isSuccess   = this.state.paymentInfo === 'success';
        if (isCancelled) {
            return (
                <div>
                    <Overlay />
                    <Modal>
                        <h4>Lessons Payment</h4>
                        <div className="alert alert-danger my-2">
                            <strong>Payment has been cancelled or didn't complete sucessfully.</strong>
                            <p>
                                If you believe this in an error, please contact administrator at <a href="mailto:lesson-payments@saanichtennisclub.org">lesson-payments@saanichtennisclub.org</a>.
                            </p>
                        </div>
                        <div className="text-right">
                            <button className="btn btn-primary" onClick={e => this.closePaymentInfo()}>Close</button>
                        </div>
                    </Modal>
                </div>
            )
        }
        if (isSuccess) {
            return (
                <div>
                    <Overlay />
                    <Modal>
                        <h4>Lessons Payment</h4>
                        <div className="alert alert-success my-2">
                            <strong>Payment has been processed sucessfully!</strong>
                        </div>
                        <div className="text-right">
                            <button className="btn btn-primary" onClick={e => this.closePaymentInfo()}>Close</button>
                        </div>
                    </Modal>
                </div>
            )
        }
    }

    render () {
        if (!this.state.isOpen) {
            return (
                <div className="alert alert-danger">
                    Registration for lessons will be available to view at: <strong>{ this.getOpenTime() }</strong>
                    <a href="/" className="d-inline-block ml-3">Reload</a>
                </div>
            );
        }

        var groups = this.state.lessons.map(group => {
            return <Group
                {...group}
                onNewSignup={this.onNewSignup}
                key={group.group}
            />
        });

        return (
            <div className="lessons d-flex flex-wrap">
                { this.renderPaymentInfo() }
                { this.state.isLoading && <div className="my-2">Checking...</div> }
                { groups }
            </div>
        );
    }
}

export default Lessons;